/**
 * Disabled ESLint's '@typescript-eslint/no-unused-vars' rule to
 * prevent it from flagging unused variables on the legacy cart functionality
 * for potential future use or reference.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { GatsbyImageProps } from 'gatsby-plugin-image';
import {
  useIsBrowser,
  coupon as getCoupon,
  useUserLocaleCountry,
} from '@bbnb/openfit-frontend-shared';
import {
  BuyBoxContainer,
  Container,
  IngredientsButton,
  IngredientsModalContent,
  ProductImageContainer,
  TitleContainer,
  Rating,
  RatingText,
  LocaleModalContent,
} from './AddToCartBlock.styles';
import { SubscribeAndSave } from 'components/Product/SubscribeAndSave/SubscribeAndSave';
import { useProductContext } from 'Context/Product/Product.context';
import { Modal, RedesignModalView } from 'components/Modal';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { StarRating } from 'components/Product/StarRating/StarRating';
import { StickyBuyButtonCta } from 'components/Product/StickyBuyButtonCta/StickyBuyButtonCta';
import { tagCartAdd } from 'helpers/Tealium';
import { GTMTagCartAdd } from 'helpers/GTM';
import { getHomepageUrl } from 'helpers/General';
import {
  awaitClick,
  logEvent,
  mapAndFormatSearchParams,
} from 'helpers/Amplitude';
import { useLocation } from '@reach/router';
import { createCheckoutUrl } from 'utils/url';
import { getReviewsData } from 'helpers/Yotpo';
import { ShippingInfo } from 'components/Product/ShippingInfo/ShippingInfo';
import { SupplementSelectorGrid } from '../SupplementSelectorGrid/SupplementSelectorGrid';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import {
  getVariantImage,
  ImageAwareObject,
} from 'Context/Product/Product.helper';
import { SanityImage } from 'graphql-types';
import {
  OpenfitProduct,
  ProductVariant,
} from 'Context/Product/Product.context.model';
import { ProductImage } from 'components/Product/ProductImage';
import { ProductImageMultiple } from 'components/Product/ProductImageMultiple';
import { Prop65Block } from 'components/Product/Prop65Block/Prop65Block';
import { useCart } from 'hooks/use-cart';
import { SanityImageAssetWithPlaceholder } from 'types';
import { Button } from 'components/Common/Button';
import { useIsMobile } from 'hooks/use-media-query';
import { navigateToUrl } from 'helpers/General';
import {
  BUY_FROM_AMAZON,
  AMAZON_HOME_PAGE_URL,
  AMAZON_SKU_REDIRECTS,
} from 'Constants';

function generateBuyUrl(variant: ProductVariant) {
  const checkoutParams: { product: string; coupon?: string } = {
    product: variant.sku || '',
  };
  const coupon = getCoupon();
  if (coupon) {
    checkoutParams.coupon = coupon;
  }

  return createCheckoutUrl(checkoutParams);
}

const TitleContainerComponent: React.FC<{ product: OpenfitProduct }> = ({
  product,
}) => {
  const { displayRating, score, totalReviews } = getReviewsData(
    product?.review,
    product?.staticReviewData
  );

  const isBrowser = useIsBrowser();

  return (
    <TitleContainer key={isBrowser.toString()}>
      <h1>{product.name}</h1>
      {displayRating && process.env.ENABLE_CUSTOMER_REVIEWS === 'true' && (
        <Rating href="#reviews">
          <StarRating rating={score} />
          <RatingText>{`${score} of 5.0 (${totalReviews} Reviews)`}</RatingText>
        </Rating>
      )}
    </TitleContainer>
  );
};

export interface AddToCartBlockProps {
  hasFreeShipping?: boolean;
  addToCartAction?: 'checkout' | 'cart';
}

export const AddToCartBlock: React.FC<AddToCartBlockProps> = ({
  hasFreeShipping = true,
  addToCartAction,
}) => {
  const { isAdding, addToCart } = useCart({
    lazyLoad: addToCartAction === 'cart',
  });
  const { pathname, search } = useLocation();
  const isBrowser = useIsBrowser();
  const isMobile = useIsMobile();
  const country = useUserLocaleCountry();

  const params = mapAndFormatSearchParams(new URLSearchParams(search));

  const {
    product,
    selectedVariant,
    subscriptionSelection,
  } = useProductContext();
  const [isNutritionModalOpen, setNutritionModalOpen] = useState(false);
  const [isLocaleModalOpen, setLocaleModalOpen] = useState(false);

  const price = selectedVariant.price || 0;

  // #region update query string with sku selection
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const initialSku = params.get('sku');

    const currentSku = selectedVariant.sku;

    if (currentSku && currentSku !== initialSku) {
      searchParams.set('sku', currentSku);
      window.history.replaceState(
        location.state,
        selectedVariant?.name || '',
        `${pathname}?${searchParams}`
      );
    }
  }, [selectedVariant]);
  // #endregion

  const ingredientImage = getSimplifiedImageData(
    selectedVariant.variantIngredient?.ingredientsImage as SanityImage
  );

  const buyUrl =
    addToCartAction !== 'cart' ? generateBuyUrl(selectedVariant) : undefined;

  let images: SanityImage[] = [];
  const variantImage = getVariantImage(selectedVariant as ImageAwareObject);
  if (variantImage) images.push(variantImage);
  if (product.images)
    images = [...images, ...(product.images as SanityImage[])];

  const gatsbyImages: GatsbyImageProps[] = [];
  const gatsbyImagesPlaceholders: GatsbyImageProps[] = [];
  for (const image of images) {
    const gatsbyImage = image?.asset?.gatsbyImageData;
    const gatsbyImagePlaceholder = (image?.asset as SanityImageAssetWithPlaceholder)
      ?.placeholder;
    if (gatsbyImage) {
      gatsbyImages.push({
        image: gatsbyImage,
        alt: `${product.name} ${selectedVariant.name}`,
      });
      gatsbyImagesPlaceholders.push({
        image: gatsbyImagePlaceholder || gatsbyImage,
        alt: `${product.name} ${selectedVariant.name}`,
      });
    }
  }

  const amazonStoreRedirect = (sku = '') => {
    const path = AMAZON_SKU_REDIRECTS[sku] || AMAZON_HOME_PAGE_URL;
    navigateToUrl(path);
  };

  const handleBuyButton: (isSticky?: boolean) => React.ReactEventHandler = (
    isSticky
  ) => {
    // if (country !== 'US') {
    //   return (event) => {
    //     event.preventDefault();
    //     setLocaleModalOpen(true);
    //   };
    // }

    const action = addToCartAction === 'cart' ? BUY_FROM_AMAZON : 'CTA';
    const sticky = isSticky ? ' Sticky' : '';
    const amplitudeEventName = `Product Page:${sticky} ${action} Clicked`;

    return awaitClick(() => {
      return Promise.all([
        GTMTagCartAdd([
          {
            id: product.stripeProductId,
            sku: selectedVariant.sku,
            name: selectedVariant.name,
            price,
          },
        ]),
        tagCartAdd([
          {
            id: product.stripeProductId,
            sku: selectedVariant.sku,
            name: selectedVariant.name,
            price,
          },
        ]),
        logEvent(amplitudeEventName, {
          sku: selectedVariant.sku,
          price,
        }),
        amazonStoreRedirect(selectedVariant.sku),
        // addToCartAction === 'cart'
        //   ? addToCart({
        //       id: selectedVariant.sku || '',
        //       quantity: 1,
        //       coupon: getCoupon() || undefined,
        //     })
        //   : Promise.resolve(),
      ]);
    });
  };

  const handleOpenModal = (e: Event) => {
    e.preventDefault();
    setNutritionModalOpen(true);

    return logEvent('CTA Click', {
      ...((params.get('icid') && { icid: params.get('icid') }) || {}),
    });
  };

  const hideSelectors =
    product.productType?.name === 'Gear' && product.variants?.length === 1;

  return (
    <Container>
      {isMobile && <TitleContainerComponent product={product} />}
      <ProductImageContainer>
        {Boolean(gatsbyImages.length) && (
          <ProductImageMultiple
            images={gatsbyImages}
            placeholders={gatsbyImagesPlaceholders}
          />
        )}
      </ProductImageContainer>
      <BuyBoxContainer>
        {!isMobile && <TitleContainerComponent product={product} />}
        {/* {!hideSelectors && <SupplementSelectorGrid />} */}
        {/* {subscriptionSelection?.value && <SubscribeAndSave />} */}
        <StickyBuyButtonCta
          handleBuyClick={handleBuyButton()}
          handleBuyStickyClick={handleBuyButton(true)}
          price={price}
          title={product.name}
          buyUrl={buyUrl}
          image={<ProductImage image={gatsbyImages[0]} />}
          addToCartAction={addToCartAction}
          disabled={!isBrowser || isAdding}
        />
        {/* <Prop65Block disclaimer={product.disclaimer} /> */}
        {/* <div style={{ paddingTop: '1rem' }}>
          <ShippingInfo
            hasFreeShipping={hasFreeShipping}
            hasSubscription={selectedVariant.recurring}
            moneyBackGuaranteeDays={selectedVariant.moneyBackPeriodDays}
          />
        </div> */}
        {ingredientImage && (
          <>
            <IngredientsButton
              variant="secondaryInverted"
              onClick={handleOpenModal}
            >
              View Nutrition Label
            </IngredientsButton>

            {isNutritionModalOpen && (
              <RedesignModalView>
                <Modal
                  showBtnClose
                  onOverlayClick={() => setNutritionModalOpen(false)}
                  onBtnCloseClick={() => setNutritionModalOpen(false)}
                >
                  <IngredientsModalContent>
                    <GatsbyImage
                      image={ingredientImage}
                      alt={selectedVariant?.variantIngredient?.name || ''}
                    />
                  </IngredientsModalContent>
                </Modal>
              </RedesignModalView>
            )}
          </>
        )}

        {/* {isLocaleModalOpen && (
          <Modal>
            <LocaleModalContent>
              <h3>Uh-oh</h3>
              <p>
                Currently, we do not support supplement purchases outside of the
                USA at this time.
              </p>
              <Button variant="secondary" href={getHomepageUrl()}>
                Continue
              </Button>
            </LocaleModalContent>
          </Modal>
        )} */}
      </BuyBoxContainer>
    </Container>
  );
};
